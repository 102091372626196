import React, { useEffect, useState } from 'react';

// Pages
import Layout from '../../components/Layout';
import SEO from '../../components/seo';

// Components
import FaqIntro from '../../components/FaqIntro';
import FaqList from '../../components/FaqList';

const Faq = () => {
  const [widthSize, setWidthSize] = useState('');

  const hasWindow = typeof window !== 'undefined';
  const isMobile = widthSize <= 480;
  const language = 'en';

  const handleWidthSize = () => {
    const widthViewPort = document.documentElement.clientWidth;

    setWidthSize(widthViewPort);
  }

  useEffect(() => {
    if (hasWindow) {
      handleWidthSize();
      window.addEventListener('resize', handleWidthSize);

      return () => {
        window.removeEventListener('resize', handleWidthSize);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      language={language}
      isMobile={isMobile}
      type='faq'
    >
      <SEO
        lang='en-US'
        title='FAQ'
        ogTitle='Manage and Boost Team’s Work, Projects and Tasks | Strytegy'
        description='➤ Manage and Boost Team’s Work, Projects and Tasks 100% Online ✅ ➤ Easily organize workflows and ✚ Start using Strategy as your work management tool!'
        alternativeLang='pt-br'
        alternativeLink='https://www.strytegy.com/faq/pt-br/'
        canonicalLink='https://www.strytegy.com/faq/en-us/'
      />
      <FaqIntro
        language={language}
        isMobile={isMobile}
      />
      <FaqList
        language={language}
        isMobile={isMobile}
      />
    </Layout>
  )
}

export default Faq;
